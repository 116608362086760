import React, { useState, useContext } from 'react';
import { withRouter } from 'react-router';
import moment from 'moment';
import { GlobalContext } from '../../global-context';
import { makeStyles, Container, Typography, Tooltip, Icon, Button, Paper, Grid } from '@material-ui/core';
import gql from 'graphql-tag';
import { Subscription } from 'react-apollo';
import Loading from '../utils/Loading';
import fragments from '../utils/graphql/fragments';
import WeeklyScheduleWeekday from '../reusable/WeeklyScheduleWeekday'
var util = require('util');

function OutboundSchedule(props) {
  const ctx = useContext(GlobalContext);
  const cls = useStyles();

  const [sunday, setSunday] = useState(moment().startOf('week'));

  // Get current date for highlighting today
  const today = moment().startOf('day');
  const currentWeekStart = moment().startOf('week');
  const isCurrentWeek = sunday.isSame(currentWeekStart, 'day');

  return (
    <div className={cls.root}>
      {/* Header section */}
      <Container maxWidth='lg' className={cls.headerContainer}>
        <div className={cls.headerRow}>
          <Typography variant='h4' className={cls.title}>
            Outbound Schedule
          </Typography>

          <div className={cls.headerActions}>
            {!isCurrentWeek && (
              <Button
                variant='outlined'
                color='primary'
                className={cls.todayButton}
                onClick={() => {
                  setSunday(moment().startOf('week'));
                }}
                startIcon={<Icon>today</Icon>}
              >
                Current Week
              </Button>
            )}
          </div>
        </div>

        {/* Week navigation */}
        <div className={cls.weekNavigation}>
          <Button
            variant='outlined'
            color='primary'
            className={cls.weekButton}
            startIcon={<Icon>chevron_left</Icon>}
            onClick={() => {
              setSunday(sunday.clone().subtract(1, 'week'));
            }}
          >
            Previous Week
          </Button>

          <Typography variant='h6' className={cls.weekDisplay}>
            {sunday.format('MMMM D')} - {sunday.clone().add(6, 'days').format('MMMM D, YYYY')}
          </Typography>

          <Button
            variant='outlined'
            color='primary'
            className={cls.weekButton}
            endIcon={<Icon>chevron_right</Icon>}
            onClick={() => {
              setSunday(sunday.clone().add(1, 'week'));
            }}
          >
            Next Week
          </Button>
        </div>
      </Container>

      {/* Calendar Grid */}
      <Subscription
        subscription={GET_ORDERS()}
        variables={{
          startDate: sunday.format(),
          endDate: sunday.clone().endOf('week').format(),
        }}
        onError={err => ctx.handleNotifications(true, `error`, `Failed to retrieve orders: ${err.toString()}`)}
      >
        {({ loading, error, data }) => {
          if (loading) return <Loading fixed />;

          if (error) {
            console.log(`Failed to retrieve orders:`, error);
            return (
              <div className={cls.errorContainer}>
                <Paper className={cls.errorPaper}>
                  <Typography variant='h6' color='error'>
                    Error Loading Orders
                  </Typography>
                  <Typography variant='body2'>{error.message}</Typography>
                </Paper>
              </div>
            );
          }

          return (
            <div className={cls.calendarContainer}>
              <div className={cls.weekGrid}>
                {/* Generate 7 equal columns, one for each day of the week */}
                {[0, 1, 2, 3, 4, 5, 6].map(dayOffset => (
                  <div key={dayOffset} className={cls.dayColumn}>
                    <WeeklyScheduleWeekday
                      sundayMoment={sunday}
                      offsetDays={dayOffset}
                      orders={data.orders.filter(
                        o => o.ship_date === sunday.clone().add(dayOffset, 'day').format('YYYY-MM-DD')
                      )}
                    />
                  </div>
                ))}
              </div>
            </div>
          );
        }}
      </Subscription>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    overflow: 'hidden',
  },
  headerContainer: {
    padding: theme.spacing(3, 3, 2),
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
    zIndex: 1,
  },
  headerRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  title: {
    fontWeight: 600,
    fontSize: '1.75rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
    },
  },
  headerActions: {
    display: 'flex',
    gap: theme.spacing(1),
  },
  todayButton: {
    textTransform: 'none',
  },
  weekNavigation: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1, 0),
  },
  weekButton: {
    textTransform: 'none',
  },
  weekDisplay: {
    fontWeight: 500,
    color: theme.palette.text.primary,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.1rem',
    },
  },
  calendarContainer: {
    flex: 1,
    overflow: 'hidden',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
  },
  weekGrid: {
    display: 'flex',
    height: '100%',
    maxHeight: 'calc(100vh - 160px)',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      maxHeight: 'none',
      overflow: 'visible',
    },
  },
  dayColumn: {
    flex: '1 1 0',
    minWidth: 0, // Important for text truncation in flex items
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
    },
  },
  errorContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(4),
  },
  errorPaper: {
    padding: theme.spacing(3),
    textAlign: 'center',
    maxWidth: 600,
  },
}));

const GET_ORDERS = () => gql`
  query getOrdersByShipDateRange($startDate: date!, $endDate: date!) {
    orders(
      order_by: { ship_date: asc }
      where: {
        active: { _eq: true }
        status: { _nin: ["Cancelled", "Canceled"] }
        _or: [{ ship_date: { _gte: $startDate, _lte: $endDate } }]
      }
    ) {
      ...Order
    }
  }
  ${fragments.order}
`;

export default withRouter(OutboundSchedule);