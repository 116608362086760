import React, { useState } from 'react';

export default function ShippingLabel({ orderId, type, width = '100%', rotate = undefined, trackingNumber }) {
  const [hasErrored, setHasErrored] = useState(false);

  const handleFirstImageError = e => {
    if (hasErrored) return; // Prevent infinite loop
    e.target.onerror = handleLastImageError;
    e.target.src = `https://app.boothbymail.com/api/orders/${orderId}/label/${type}`;
  };

  const handleLastImageError = e => {
    if (hasErrored) return; // Prevent infinite loop
    setHasErrored(true);
    e.target.onerror = null;
    // Use a data URI for a simple placeholder instead of external service
    e.target.src = `data:image/svg+xml;charset=UTF-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1040' height='594' viewBox='0 0 1040 594'%3E%3Crect fill='%23f8f9fa' width='1040' height='594'/%3E%3Ctext fill='%23495057' font-family='sans-serif' font-size='20' dy='10.5' font-weight='bold' x='520' y='297' text-anchor='middle'%3ENo ${type} shipping label for order ${orderId}%3C/text%3E%3C/svg%3E`;
  };

  const src = `https://s3.us-east-1.amazonaws.com/labels.boothbymail.com/${
    process.env.REACT_APP_SITE_ENV
  }/${orderId}/${type}?trackingNumber=${trackingNumber}&u=${new Date().getTime()}`;

  const handleImageClick = (e, data) => {
    window.open(e.target.src, '_blank');
  };

  return (
    <img
      src={src}
      onError={handleFirstImageError}
      width={width}
      style={rotate ? { transform: 'rotate(90deg)' } : {}}
      onClick={handleImageClick}
      alt={`${type} shipping label`}
    />
  );
}
