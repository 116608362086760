import React from 'react';
import { withRouter } from "react-router-dom";
import moment from 'moment';
import OrderCard from './OrderCard'
import PrintableShippingLabels from './PrintableShippingLabels'
import { makeStyles, Typography, Tooltip, Icon, Button, Box, Grid, Chip, Badge } from '@material-ui/core';

function WeeklyScheduleWeekday({ sundayMoment, offsetDays = 0, orders }) {
  const cls = useStyles();
  const today = moment().startOf('day');
  const dayDate = sundayMoment.clone().add(offsetDays, 'day');
  const isToday = dayDate.isSame(today, 'day');

  // Group orders by status
  const pendingOrders = orders.filter(order => ['New', 'Ready'].includes(order.status));
  const shippedOrders = orders.filter(order => ['Outbound', 'Delivered'].includes(order.status));

  return (
    <div className={cls.dayColumn}>
      {/* Day Header */}
      <div className={`${cls.dayHeader} ${isToday ? cls.todayHeader : ''}`}>
        <div className={cls.dayInfo}>
          <Typography variant='subtitle2' className={cls.dayName}>
            {moment.weekdaysShort(dayDate.weekday())}
          </Typography>
          <Typography variant='h5' className={cls.dayNumber}>
            {dayDate.date()}
          </Typography>
        </div>

        <div className={cls.dayActions}>
          {orders.length > 0 && (
            <>
              <Badge badgeContent={orders.length} color='primary' className={cls.orderBadge}>
                <Icon fontSize='small'>event_note</Icon>
              </Badge>

              <PrintableShippingLabels
                orders={orders}
                type='all'
                hide
                trigger={
                  <Button
                    size='small'
                    variant='outlined'
                    color='primary'
                    className={cls.printButton}
                    startIcon={<Icon fontSize='small'>print</Icon>}
                  >
                    Print
                  </Button>
                }
              />
            </>
          )}
        </div>
      </div>

      {/* Orders Container */}
      <div className={cls.ordersContainer}>
        {/* Pending Orders */}
        {pendingOrders.length > 0 && (
          <div className={cls.orderSection}>
            <div className={cls.sectionHeader}>
              <Typography variant='body2' className={cls.sectionTitle}>
                Needs Action ({pendingOrders.length})
              </Typography>
            </div>

            <div className={cls.ordersList}>
              {pendingOrders.map(order => (
                <Grid item xs={12} key={order.id} className={cls.orderCardWrapper}>
                  <OrderCard order={order} />
                </Grid>
              ))}
            </div>
          </div>
        )}

        {/* Shipped Orders */}
        {shippedOrders.length > 0 && (
          <div className={cls.orderSection}>
            <div className={cls.sectionHeader}>
              <Typography variant='body2' className={cls.sectionTitle}>
                Completed ({shippedOrders.length})
              </Typography>
            </div>

            <div className={cls.ordersList}>
              {shippedOrders.map(order => (
                <Grid item xs={12} key={order.id} className={cls.orderCardWrapper}>
                  <OrderCard order={order} />
                </Grid>
              ))}
            </div>
          </div>
        )}

        {/* Empty State */}
        {orders.length === 0 && (
          <div className={cls.emptyState}>
            <Typography variant='body2' color='textSecondary'>
              No shipments scheduled
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
}

export default withRouter(WeeklyScheduleWeekday);

const useStyles = makeStyles(theme => ({
  dayColumn: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${theme.palette.divider}`,
    borderRight: 0,
    '&:last-child': {
      borderRight: `1px solid ${theme.palette.divider}`,
    },
    backgroundColor: theme.palette.background.paper,
  },
  dayHeader: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.default,
  },
  todayHeader: {
    backgroundColor: theme.palette.primary.light,
    '& $dayName, & $dayNumber': {
      color: theme.palette.primary.contrastText,
    },
  },
  dayInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  dayName: {
    fontWeight: 500,
    fontSize: '0.9rem',
  },
  dayNumber: {
    fontWeight: 500,
    fontSize: '1.5rem',
  },
  dayActions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  orderBadge: {
    marginRight: theme.spacing(1),
  },
  printButton: {
    fontSize: '0.7rem',
    padding: theme.spacing(0.5, 1),
  },
  ordersContainer: {
    flex: 1,
    overflowY: 'auto',
    padding: theme.spacing(1),
  },
  orderSection: {
    marginBottom: theme.spacing(2),
  },
  sectionHeader: {
    marginBottom: theme.spacing(1),
  },
  sectionTitle: {
    fontWeight: 500,
    color: theme.palette.text.secondary,
    fontSize: '0.85rem',
  },
  ordersList: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  orderCardWrapper: {
    marginBottom: theme.spacing(1),
  },
  emptyState: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: 0.5,
    padding: theme.spacing(3),
  },
}));