import React, { Component } from "react";
import "./index.css";
import { GlobalContext } from "./global-context";
import Layout from "./components/Layout";
import Auth0Lock from "auth0-lock";
import ApolloClient from "apollo-client"
import { WebSocketLink } from "apollo-link-ws"
import { HttpLink } from "apollo-link-http"
import { split } from "apollo-link"
import { getMainDefinition } from "apollo-utilities"
import { InMemoryCache } from "apollo-cache-inmemory"
import { setContext } from "apollo-link-context"
import { ApolloProvider } from "react-apollo"
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { withWidth } from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import axios from 'axios'
import BoothByMail from '@BoothByMail/boothbymail-sdk'

let log = false

const theme = createMuiTheme({
  palette: {
    // Modern Theme Colors
    primary: { light: '#5eb8ff', main: '#1976d2', dark: '#004ba0', contrastText: '#fff' },
    secondary: { light: '#718792', main: '#455a64', dark: '#1c313a', contrastText: '#fff' },
    warning: { light: '#ffcd38', main: '#ffa000', dark: '#c67100', contrastText: '#000' },
    error: { superLight: '#ffebee', light: '#ef5350', main: '#e53935', dark: '#c62828', contrastText: '#fff' },
    success: { light: '#80e27e', main: '#4caf50', dark: '#087f23', contrastText: '#fff' },
    info: { light: '#63a4ff', main: '#1976d2', dark: '#004ba0', contrastText: '#fff' },

    // Text Colors
    text: {
      primary: '#263238',
      secondary: '#546e7a',
      tertiary: '#78909c',
      disabled: '#b0bec5',
      hint: '#b0bec5',
    },

    // Custom Colors
    custom: {
      light: '#fafafa',
      medium: '#f5f5f5',
      dark: '#e0e0e0',
    },

    // Action Colors
    action: {
      hover: '#f5f5f5',
      selected: '#e3f2fd',
      disabledBackground: '#eceff1',
    },

    // Background Colors
    background: {
      default: '#f9fbfd',
      paper: '#ffffff',
    },

    // Border/Divider Colors
    divider: '#eceff1',
  },

  typography: {
    fontFamily: ['Roboto', '-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'Arial', 'sans-serif'].join(','),
    h1: {
      fontWeight: 500,
    },
    h2: {
      fontWeight: 500,
    },
    h3: {
      fontWeight: 500,
    },
    h4: {
      fontWeight: 500,
    },
    h5: {
      fontWeight: 500,
    },
    h6: {
      fontWeight: 500,
    },
  },

  shape: {
    borderRadius: 8,
  },

  overrides: {
    MuiCard: {
      root: {
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.05)',
        transition: 'box-shadow 0.3s ease, transform 0.3s ease',
        '&:hover': {
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        },
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none',
        fontWeight: 500,
        borderRadius: 8,
      },
      contained: {
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        '&:hover': {
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)',
        },
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: 8,
      },
      elevation1: {
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.05)',
      },
      elevation2: {
        boxShadow: '0 3px 10px rgba(0, 0, 0, 0.08)',
      },
    },
    MuiTableCell: {
      root: {
        padding: '12px 16px',
      },
    },
    MuiListItem: {
      root: {
        borderRadius: 8,
        transition: 'background-color 0.2s ease',
      },
    },
    MuiAppBar: {
      root: {
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.08)',
      },
    },
  },

  props: {
    MuiButtonBase: {
      disableRipple: false,
    },
  },
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userIsAuthenticated: () => {
        if (this.state.userProfile.email) {
          return true;
        } else return false;
      },
      theme: theme,
      width: props.width,
      notificationShow: false,
      notificationVariant: '',
      notificationMessage: '',
      handleNotifications: async (
        show,
        variant = this.state.notificationVariant,
        message = this.state.notificationMessage
      ) =>
        await this.setState({
          notificationShow: show,
          notificationVariant: variant,
          notificationMessage: message,
        }),
      userProfile: {},
      userToken: '',
      redirectPath: null,
      lock: new Auth0Lock(process.env.REACT_APP_AUTH_CLIENT_ID, process.env.REACT_APP_AUTH_DOMAIN, {
        id: 'login',
        cacheLocation: 'localstorage',
        useRefreshTokens: true,
        allowSignUp: false,
        container: 'login',
        theme: {
          logo: 'https://app.boothbymail.com/assets/img/BoothByMail_logo_monochrome%20[web-white-180].png',
          primaryColor: '#3f51b5',
        },
        languageDictionary: {
          title: '',
        },
        auth: {
          redirectUrl:
            !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
              ? `http://localhost:8888/login`
              : `https://${process.env.REACT_APP_SUBDOMAIN}.boothbymail.com/login`,
          responseType: 'code token id_token',
          params: {
            scope: 'openid email profile', // Learn about scopes: https://auth0.com/docs/scopes
          },
        },
      }),
      logout: () => {
        if (log) {
          console.log('running logout function');
        }
        this.state.lock.logout({
          returnTo:
            !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
              ? `http://localhost:8888/login`
              : `https://${process.env.REACT_APP_A0_CB_SD}.boothbymail.com/login`,
        });
        this.state.userProfile.email = null;
      },
      setUserAuth: (profile, token) => {
        if (log) console.log('profile', profile, 'and token', token);
        profile.bbm_user_id = profile['https://hasura.io/jwt/claims']['x-bbm-user-id'];
        this.setState({ userProfile: profile });
        this.setState({ userToken: token });
      },
      apolloClient: {},
      setupApollo: token => {
        if (log) {
          console.log('Setting up the Apollo...');
        }
        const authLink = setContext(async (_, { headers }) => {
            return {
              headers: {
                ...headers,
                authorization: `Bearer ${token}`,
              },
            };
          }),
          wsurl = process.env.REACT_APP_GRAPHQL_WSS_URL,
          httpurl = process.env.REACT_APP_GRAPHQL_URL,
          wsLink = new WebSocketLink({
            uri: wsurl,
            options: {
              lazy: true,
              reconnect: true,
              timeout: 30000,
              connectionParams: async () => {
                return {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                };
              },
            },
          }),
          httpLink = new HttpLink({
            uri: httpurl,
          }),
          link = split(
            // split based on operation type
            ({ query }) => {
              const { kind, operation } = getMainDefinition(query);
              return kind === 'OperationDefinition' && operation === 'subscription';
            },
            wsLink,
            authLink.concat(httpLink)
          ),
          client = new ApolloClient({
            link,
            cache: new InMemoryCache(),
          });
        if (log) {
          console.log('Apollo Client Initialized! ', client);
        }
        this.setState({ apolloClient: client });
        BoothByMail.configure({ apollo_client: client });
      },
      BoothByMail: BoothByMail,
    };
  }

  render() {
    if (this.state.userIsAuthenticated()) {
      log && console.log('Rendering with ApolloProvider now...');
      log && console.log(this.lock);
      return (
        <MuiThemeProvider theme={theme}>
          <GlobalContext.Provider value={this.state}>
            <ApolloProvider client={this.state.apolloClient}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <Layout />
              </MuiPickersUtilsProvider>
            </ApolloProvider>
          </GlobalContext.Provider>
        </MuiThemeProvider>
      );
    } else {
      if (log) {
        console.log('Rendering without ApolloProvider until user is logged in.');
      }
      return (
        <MuiThemeProvider theme={theme}>
          <GlobalContext.Provider value={this.state}>
            <Layout />
          </GlobalContext.Provider>
        </MuiThemeProvider>
      );
    }
  }
}

export default withWidth()(App);
