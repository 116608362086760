import React from 'react';
import { withRouter, Link } from "react-router-dom";
import moment from 'moment';
import { makeStyles, Typography, Tooltip, Icon, Box, Chip, Paper } from '@material-ui/core';

function OrderCard({ order }) {
  const cls = useStyles();

  // Determine order status properties
  const orderStatus = order.status;
  const isShipped = ['Outbound', 'Delivered'].includes(orderStatus);
  const isReady = orderStatus === 'Ready';
  const isNew = orderStatus === 'New';

  // Determine status classes and properties
  let statusClass = cls.statusNew;
  let statusIcon = 'error_outline';
  let statusText = 'Needs Prep';
  let statusBadgeClass = cls.statusBadgeNew;

  if (isShipped) {
    statusClass = cls.statusShipped;
    statusIcon = 'check_circle';
    statusText = orderStatus;
    statusBadgeClass = cls.statusBadgeShipped;
  } else if (isReady) {
    statusClass = cls.statusReady;
    statusIcon = 'local_shipping';
    statusText = 'Ready to Ship';
    statusBadgeClass = cls.statusBadgeReady;
  }

  // Prepare additional order information
  const eventDate = moment(order.event_date);
  const shipDate = moment(order.ship_date);
  const daysUntilShip = Math.max(0, shipDate.diff(moment(), 'days'));
  const isUrgent = daysUntilShip <= 1 && !isShipped;

  return (
    <Link to={`/orders/${order.id}`} className={cls.cardLink}>
      <Paper
        className={`${cls.card} ${isShipped ? cls.shippedCard : ''} ${isUrgent ? cls.urgentCard : ''}`}
        elevation={isUrgent ? 3 : 1}
      >
        {/* Status Border */}
        <div className={`${cls.statusBorder} ${statusClass}`} />

        {/* Card Content */}
        <div className={cls.cardContent}>
          {/* Header with City/State and Order ID */}
          <div className={cls.cardHeader}>
            <div className={cls.locationContainer}>
              <Icon fontSize='small' className={cls.locationIcon}>
                location_on
              </Icon>
              <Typography variant='subtitle1' className={cls.locationText} noWrap>
                {order.shipping_city}, {order.shipping_state}
              </Typography>
            </div>
            <Typography variant='caption' className={cls.orderId}>
              #{order.id}
            </Typography>
          </div>

          {/* Event name as secondary info */}
          <Typography variant='body2' className={cls.eventName} noWrap>
            {order.event_name}
          </Typography>

          {/* Transit Information */}
          <div className={cls.infoRow}>
            <div className={cls.infoItem}>
              <Icon fontSize='small' className={cls.infoIcon}>
                schedule
              </Icon>
              <Typography variant='body2' className={cls.infoText}>
                {order.transit_days} days transit
              </Typography>
            </div>
          </div>

          {/* Dates */}
          <div className={cls.datesRow}>
            <div className={cls.dateItem}>
              <Typography variant='caption' className={cls.dateLabel}>
                Ship:
              </Typography>
              <Typography variant='body2' className={cls.dateValue}>
                {shipDate.format('MMM D')}
              </Typography>
            </div>
            <Icon fontSize='small' className={cls.dateArrow}>
              arrow_forward
            </Icon>
            <div className={cls.dateItem}>
              <Typography variant='caption' className={cls.dateLabel}>
                Event:
              </Typography>
              <Typography variant='body2' className={cls.dateValue}>
                {eventDate.format('MMM D')}
              </Typography>
            </div>
          </div>

          {/* Props */}
          <div className={cls.propsRow}>
            <Typography variant='caption' className={cls.propsLabel}>
              Props:
            </Typography>
            <Typography variant='body2' className={cls.propsValue} noWrap>
              {order.props || 'None'}
            </Typography>
          </div>

          {/* Order attributes */}
          <div className={cls.attributesRow}>
            <Tooltip title={`${order.print_count} prints`}>
              <div className={cls.iconBadge}>
                <Icon fontSize='small'>print</Icon>
                {order.print_count}
              </div>
            </Tooltip>
            <Tooltip title={order.custom_logo ? 'Custom logo' : 'Default logo'}>
              <div className={cls.iconBadge}>
                <Icon fontSize='small'>filter_vintage</Icon>
                {order.custom_logo ? 'Custom' : 'Default'}
              </div>
            </Tooltip>
            <Tooltip title={`${order.backdrop_color} backdrop`}>
              <div className={cls.iconBadge}>
                <Icon fontSize='small'>bookmark_border</Icon>
                {order.backdrop_color}
              </div>
            </Tooltip>
            {isUrgent && !isShipped && (
              <Chip size='small' className={cls.urgentChip} label='URGENT' color='secondary' />
            )}
          </div>

          {/* Status Footer */}
          <div className={`${cls.statusFooter} ${statusClass}`}>
            <div className={`${cls.statusBadge} ${statusBadgeClass}`}>
              <Icon fontSize='inherit' style={{ marginRight: '4px', fontSize: '0.8rem' }}>
                {statusIcon}
              </Icon>
              <span>{statusText}</span>
            </div>
          </div>
        </div>
      </Paper>
    </Link>
  );
}

const useStyles = makeStyles(theme => ({
  cardLink: {
    textDecoration: 'none',
    display: 'block',
    transition: 'transform 0.2s ease',
    '&:hover': {
      transform: 'translateY(-3px)',
    },
  },
  card: {
    position: 'relative',
    overflow: 'hidden',
    borderRadius: theme.shape.borderRadius,
    boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
    transition: 'box-shadow 0.2s ease',
    '&:hover': {
      boxShadow: '0 3px 8px rgba(0,0,0,0.15)',
    },
    paddingLeft: '8px', // Make room for status border
  },
  shippedCard: {
    backgroundColor: 'rgba(0,0,0,0.02)',
    opacity: 0.8,
  },
  urgentCard: {
    borderLeft: `4px solid ${theme.palette.error.main}`,
    paddingLeft: '4px', // Adjust for the urgent border
  },
  statusBorder: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    width: '8px',
  },
  statusNew: {
    backgroundColor: theme.palette.warning.main,
  },
  statusReady: {
    backgroundColor: theme.palette.info.main,
  },
  statusShipped: {
    backgroundColor: theme.palette.success.main,
  },
  cardContent: {
    padding: theme.spacing(1.5),
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(0.5),
  },
  locationContainer: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    marginRight: theme.spacing(1),
  },
  locationIcon: {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(0.5),
    fontSize: '1rem',
  },
  locationText: {
    fontWeight: 600,
    fontSize: '1rem',
    color: theme.palette.primary.dark,
  },
  eventName: {
    fontSize: '0.85rem',
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
  },
  orderId: {
    color: theme.palette.text.secondary,
    fontWeight: 400,
  },
  infoRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  infoItem: {
    display: 'flex',
    alignItems: 'center',
  },
  infoIcon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(0.5),
    fontSize: '0.9rem',
  },
  infoText: {
    fontSize: '0.8rem',
    color: theme.palette.text.secondary,
  },
  datesRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    padding: theme.spacing(0.5, 0),
    backgroundColor: 'rgba(0,0,0,0.03)',
    borderRadius: theme.shape.borderRadius,
  },
  dateItem: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0, 1),
  },
  dateLabel: {
    color: theme.palette.text.secondary,
    fontSize: '0.7rem',
  },
  dateValue: {
    fontSize: '0.85rem',
    fontWeight: 500,
  },
  dateArrow: {
    color: theme.palette.text.disabled,
    fontSize: '0.8rem',
  },
  propsRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  propsLabel: {
    color: theme.palette.text.secondary,
    fontSize: '0.7rem',
    marginRight: theme.spacing(0.5),
  },
  propsValue: {
    fontSize: '0.8rem',
  },
  attributesRow: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: theme.spacing(0.75),
    marginBottom: theme.spacing(1.5),
  },
  iconBadge: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.7rem',
    backgroundColor: 'rgba(0,0,0,0.05)',
    padding: '2px 6px',
    borderRadius: '4px',
    '& .MuiIcon-root': {
      fontSize: '0.9rem',
      marginRight: '4px',
    },
  },
  statusFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(-1.5),
    marginRight: theme.spacing(-1.5),
    marginBottom: theme.spacing(-1.5),
    borderTop: '1px solid rgba(0,0,0,0.05)',
    padding: theme.spacing(0.75, 1.5),
  },
  statusBadge: {
    borderRadius: '4px',
    padding: '2px 6px',
    fontSize: '0.7rem',
    fontWeight: 'bold',
    display: 'inline-flex',
    alignItems: 'center',
  },
  statusBadgeNew: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.dark,
  },
  statusBadgeReady: {
    backgroundColor: theme.palette.info.light,
    color: theme.palette.info.dark,
  },
  statusBadgeShipped: {
    backgroundColor: theme.palette.success.light,
    color: theme.palette.success.dark,
  },
  urgentChip: {
    height: 20,
    fontSize: '0.65rem',
    fontWeight: 600,
  },
}));

export default withRouter(OrderCard);